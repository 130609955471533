import React from "react"

import { Link } from "gatsby"

import {
  PhoneToPhone,
  PhoneToScreen,
  ScreenIcon,
  ShareIcon,
} from "../../../images/RTC/Icons"

import "./Solutions.scss"

export default function Solutions() {
  return (
    <div className="rtc-solutions-container">
      <div className="rtc-solutions-wrapper">
        <h2 className="rtc-solutions-header">
          Custom web & mobile WebRTC solutions
        </h2>
        <div className="rtc-text-container">
          <div className="rtc-row">
            <div className="rtc-article">
              <div className="rtc-article-header-container">
                <img src={ScreenIcon} className="rtc-article-icon" />
                <h4 className="rtc-article-header">
                  High-definition video communication
                </h4>
              </div>
              <div className="rtc-article-text">
                Our experienced team can help you implement secure audio/video
                streaming across multiple browsers. The technologies we use are
                available on all modern browsers as well as on native clients
                for all major platforms.
              </div>
            </div>
            <div className="rtc-article">
              <div className="rtc-article-header-container">
                <img src={PhoneToScreen} className="rtc-article-icon" />
                <h4 className="rtc-article-header">
                  Phone-to-browser connections
                </h4>
              </div>
              <div className="rtc-article-text">
                At OS-System we can create connections between Public Switched
                Telephone Network and browsers. We are using modern technologies
                and best practises to deliver the best quality guaranteed.
              </div>
            </div>
          </div>
          <div className="rtc-row">
            <div className="rtc-article">
              <div className="rtc-article-header-container">
                <img src={ShareIcon} className="rtc-article-icon" />
                <h4 className="rtc-article-header">
                  Messaging and file sharing
                </h4>
              </div>
              <div className="rtc-article-text">
                We establish secure connections that allow your software to
                shares data and eliminate the need to store files in the cloud
                or data centers by delivering data directly.
              </div>
            </div>
            <div className="rtc-article">
              <div className="rtc-article-header-container">
                <img src={PhoneToPhone} className="rtc-article-icon" />
                <h4 className="rtc-article-header">
                  Mobile-to-mobile connections
                </h4>
              </div>
              <div className="rtc-article-text">
                Our services are not limited to running on desktop web browsers.
                We can develop modern solutions for iOS and Android devices
                based on our
                <Link
                  to="/expertise/cross-platform/"
                  className="rtc-article-link"
                >
                  {" "}
                  strong cross-platform development experience.
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
