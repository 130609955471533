import React from "react"

import { ChattingRobots } from "../../..//images/RTC/Graphics"

import "./Banner.scss"

export default function Banner() {
  return (
    <div className="rtc-banner-container">
      <div className="rtc-banner-wrapper">
        <h1 className="rtc-banner-header">Real Time Communication</h1>
        <div className="chat-robot-container">
          <ChattingRobots />
        </div>
      </div>
    </div>
  )
}
