import React from "react"
import SEO from "../../components/CommonComponents/SEO"
import Layout from "../../components/CommonComponents/Layout"
import Banner from "../../components/RTC/Banner"
import Solutions from "../../components/RTC/Solutions"
import Discover from "../../components/RTC/Discover"
import Cases from "../../components/CommonComponents/Cases"
import ContactUsExpertise from "../../components/CommonComponents/ContactUsExpertise"
import { mainUrl } from "../../js/config"
import dataForProjects from "../../information/RTC/dataForProjects.json"

const settings = {
  dots: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  responsive: [
    {
      breakpoint: 1021,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        className: "center",
      },
    },
  ],
}

export default function RTC() {
  const sendUrl = typeof window !== "undefined" ? window?.location?.href : ""
  return (
    <div>
      <SEO
        title="Solutions in WebRTC Development from OSSystem Company"
        description="Real time communication and WebRTC development solutions From OSSystem developers. Create mobile-to-mobile, phone-to-browser and other types of communication with us"
        canonical={`${mainUrl}/expertise/rtc/`}
      />
      <Layout showFormPoint={100}>
        <Banner />
        <Solutions />
        <Discover />
        <Cases
          projects={dataForProjects}
          settings={settings}
          headerText="Work Projects"
        />
        <ContactUsExpertise form={0} url={sendUrl} />
      </Layout>
    </div>
  )
}
