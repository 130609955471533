import React from "react"

import { Link } from "gatsby"

import { CameraRobot } from "../../../images/RTC/Graphics"

import "./Discover.scss"

export default function Discover() {
  return (
    <div className="discover-container">
      <div className="discover-wrapper">
        <div className="discover-robot-container">
          <CameraRobot />
        </div>
        <div className="discover-text-container">
          <h3 className="discover-header">
            Discover how WebRTC solutions can boost your business
          </h3>
          <div className="discover-text">
            As a rule, we don’t limit the range of industries we work with. We
            have many projects in e-commerce, healthcare projects, many projects
            on business process automation and others. We are proud that our
            customers are returning to us every time they need development or
            enhancement of their products.
          </div>
          <Link className="discover-btn" to="/estimate/">
            Get a free quoute
          </Link>
        </div>
      </div>
    </div>
  )
}
